import { SET_MANAGERS, ADD_MANAGER, UPDATE_MANAGER, DELETE_MANAGER, LOGOUT } from '../redux/action';

const initialState = [];



const GetManagers = (state = initialState, action) => {
    switch (action.type) {
        case SET_MANAGERS:
            return action.payload;
        case ADD_MANAGER:
            return [...state, action.payload];
        case UPDATE_MANAGER:
            return state.map(manager =>
                manager.managerId === action.payload.managerId ? action.payload : manager
            );
        case DELETE_MANAGER:
            return state.filter(manager => manager.managerId !== action.payload);
        
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default GetManagers;