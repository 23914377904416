import { ADD_SELECTED_ITEM } from "./action";


const initialState = [];

const selectedItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SELECTED_ITEM:
            return [...state, action.payload];
        default:
            return state;
    }
};

export default selectedItemsReducer;
