import { ADD_LEAVE, LOGOUT } from "./action";

const initialState = {};

const GetLeave = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LEAVE:
            return {
                ...state,
                ...action.payload,
            };

        case 'UPDATE_LEAVE_STATUS':
            return {
                ...state,
                leaveData: state.leaveData.map(leave =>
                    leave.leaverequestid === action.payload.leaverequestid
                        ? { ...leave, status: action.payload.newStatus }
                        : leave
                )
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default GetLeave;
