import { ADD_DESIGNATION, LOGOUT, REMOVE_DESIGNATION, SET_DESIGNATIONS, UPDATE_DESIGNATION } from "./action";

// In your reducer.js file
const initialState = { designations: [] };


const designationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DESIGNATIONS:
      return {
        ...state,
        designations: action.payload,
      };
    case ADD_DESIGNATION:
      return [
        ...state.designations,
        ...action.payload.designations,
      ];
    case UPDATE_DESIGNATION:
      return {
        ...state,
        designations: state.designations.map(desig =>
          desig._id === action.payload._id ? { ...desig, ...action.payload } : desig
        ),
      };
    case REMOVE_DESIGNATION:
      return {
        ...state,
        designations: state.designations.filter(desig => desig._id !== action.payload.designations),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default designationReducer;