// allowancesReducer.js
import { NON_TAXABLE } from './action';

const initialState = [];

const nontaxableReducer = (state = initialState, action) => {
  switch (action.type) {
    case NON_TAXABLE:
      return [...state, action.payload]


 
    default:
      return state;
  }
};

export default nontaxableReducer;
