import { ADD_PAY, LOGOUT } from "./action";

const initialState = {};

const GetPayroll = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PAY:
            return {
                ...state,
                ...action.payload,
            };

        case 'UPDATE_PAYROLL_STATUS':
            return {
                ...state,
                payroll: {
                    ...state.payroll,
                    employees: state.payroll.employees.map(employee =>
                        employee.employeeid === action.payload.employeeId
                            ? { ...employee, status: action.payload.newStatus }
                            : employee
                    )
                }
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default GetPayroll;
