// allowancesReducer.js
import { ADD_ALLOWANCE, LOGOUT, REMOVE_ALLOWANCE, RESET_ALLOWANCES } from './action';

const initialState = [];

const allowancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALLOWANCE:
      return [...state, action.payload]

    case REMOVE_ALLOWANCE:
      return state.filter((_, index) => index !== action.payload);
    case RESET_ALLOWANCES:
      return initialState;
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default allowancesReducer;
