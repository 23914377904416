import { ADD_EMP_PROFILE } from "./action";

const initialState = {};

const GetEmpProfile = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EMP_PROFILE:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default GetEmpProfile;
