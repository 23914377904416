import { ADD_DOCS, LOGOUT, REMOVE_DOCS, RESET_DOCS } from "./action";


const initialState = [];

const addDocsReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_DOCS:
            return [...state, action.payload]

        case REMOVE_DOCS:
            return state.filter((_, index) => index !== action.payload);

        case RESET_DOCS:
            return initialState;
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default addDocsReducer;
