// allowancesReducer.js
import { ADD_DEDUCTION, LOGOUT, REMOVE_DEDUCTION, RESET_DEDUCTIONS } from './action';

const initialState = [];

const deductioReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEDUCTION:
      return [...state, action.payload]
    case REMOVE_DEDUCTION:
      return state.filter((_, index) => index !== action.payload);
    case RESET_DEDUCTIONS:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default deductioReducer;
