import { ADD_DRIVER } from "./action";

const initialState = {};

const AddDriver = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DRIVER:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}

export default AddDriver
