import { ADD_TRANSACTION, LOGOUT } from "./action";

const initialState = {};

const GetTransaction = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TRANSACTION:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default GetTransaction;
