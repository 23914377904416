// SplashScreen.js
import React from 'react';
import './SplashScreen.css';
import TLogo from '../Data/IMG/T-logo-1.png'

const SplashScreen = () => {
    return (
        <div className="splash-screen">
            <div className="splash-text">
                <img src={TLogo} alt="" className='w-full' />
            </div>
        </div>
    );
};

export default SplashScreen;
