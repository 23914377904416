import { IMAGE_DOCS, LOGOUT, REMOVE_IMAGE } from "./action";


const initialState = [];

const addImageReducer = (state = initialState, action) => {

    switch (action.type) {

        case IMAGE_DOCS:
            return [...state, action.payload]

        case REMOVE_IMAGE:
          return initialState;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default addImageReducer;