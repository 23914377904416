import { FILE_DOCS, LOGOUT, REMOVE_FILE } from "./action";


const initialState = [];

const addFileReducer = (state = initialState, action) => {

    switch (action.type) {

        case FILE_DOCS:
            return [...state, action.payload]

        case REMOVE_FILE:
          return initialState

        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default addFileReducer;
