// In your reducer.js file
import { ADD_DEPARTMENT, LOGOUT, REMOVE_DEPARTMENT, SET_DEPARTMENTS, UPDATE_DEPARTMENT } from "./action";

const initialState = {
  departments: [],
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
      case ADD_DEPARTMENT:
        const newState = {
          ...state,
          departments: [...state.departments, action.payload],
        };
        return newState;
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.map(dept =>
          dept._id === action.payload._id ? action.payload : dept
        ),
      };
    case REMOVE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter(dept => dept._id !== action.payload),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default departmentReducer;