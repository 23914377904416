import { ADD_EMP, LOGOUT } from "./action";

const initialState = {};

const AddEmployee = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMP:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default AddEmployee;
