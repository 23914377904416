import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import SplashScreen from 'components/SplashScreen';
// import CustomProgressBar from 'components/customProgressBar';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = lazy(() => import('./App'));

const AppWithSuspense = () => (
  <Suspense fallback={
    <SplashScreen/>
  }>
    <App />
  </Suspense> 
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppWithSuspense />
      </PersistGate>
    </Provider>
    <ToastContainer limit={1} />
  </React.StrictMode>
);
