import { ADD_BIRTHDAY, LOGOUT } from "./action";

const initialState = {};

const GetBirthday = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BIRTHDAY:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default GetBirthday;
