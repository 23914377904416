import { ADD_ATTEND, LOGOUT } from "./action";

const initialState = {};

const GetAttendance = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ATTEND:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default GetAttendance;
