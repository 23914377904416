
import { DEPENDENTS, LOGOUT, REMOVE_DEPENDENTS, RESET_DEPENDENTS } from './action';

const initialState = [];

const dependentsReducer = (state = initialState, action) => {
  switch (action.type) {

    case DEPENDENTS:
      return [...state, action.payload]

    case REMOVE_DEPENDENTS:
      return state.filter((_, index) => index !== action.payload);

    case RESET_DEPENDENTS:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default dependentsReducer;
