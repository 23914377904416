// allowancesReducer.js
import { ADD_CONTRIBUTION, LOGOUT, REMOVE_CONTRIBUTION, RESET_CONTRIBUTIONS } from './action';

const initialState = [];

const contributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTRIBUTION:
      return [...state, action.payload]
    case REMOVE_CONTRIBUTION:
      return state.filter((_, index) => index !== action.payload);
    case RESET_CONTRIBUTIONS:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default contributionReducer;
