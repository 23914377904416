// allowancesReducer.js
import { TAXABLE } from './action';

const initialState = [];

const taxableReducer = (state = initialState, action) => {
  switch (action.type) {
    case TAXABLE:
      return [...state, action.payload]


 
    default:
      return state;
  }
};

export default taxableReducer;
